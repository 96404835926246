import React from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { ZIndex } from '@styles/z-index';
import { ThemeProps } from '@theme/base';
import { AppVariant } from '@model/config/brands';
import { PromoBannerData } from '@model/promo-banner';
import { disallowedPages } from '@model/common/pages/promo-banner-pages';
import { TripTypes } from '@model/common';
import { ModalType, showModal } from '@state/modal/modalOperations';
import { useResize } from '@components/hooks';
import { CountdownBanner } from '../countdown/CountdownBanner';

export interface PromoBannerProps {
  currentPath: string;
  promoBanners: Array<PromoBannerData>;
  tripType: string | null;
}

const PromoBanner = styled(CountdownBanner)(({ theme }: ThemeProps) => ({
  padding: theme.custom.spacing.small,
  zIndex: ZIndex.CONTENT
}));

export const PromoBannerComponent = ({ currentPath, promoBanners, tripType }: PromoBannerProps) => {
  const dispatch = useDispatch();
  const [_, isMobile] = useResize();
  const isWhiteLabel = AppVariant.isWhitelabel();
  if (isWhiteLabel) {
    return null;
  }

  if (!promoBanners?.length) {
    return null;
  }

  const getBasePath = (currentPath: string): string => {
    const [basePath] = currentPath.split('?');
    return basePath;
  };

  const pagesIncludesPath = (pages: string[], currentPath: string): boolean => {
    return pages.includes(getBasePath(currentPath));
  };

  const pagesMatchesPath = (pages: string[], currentPath: string): boolean => {
    const basePath = getBasePath(currentPath);
    return pages.some((path) => basePath === path || basePath.startsWith(`${path}/`) || basePath.endsWith(`${path}/`));
  };

  const pagesHasPathSegment = (pages: string[], currentPath: string): boolean => {
    const pathSegments = getBasePath(currentPath)
      .split('/')
      .filter((segment) => segment.length > 0);

    return pages.some((path) => {
      return pathSegments.some((segment) => path.includes(`/${segment}`) || path.includes(segment));
    });
  };

  const displayBanner = (banner: PromoBannerData, currentPath: string, tripTypeProps: string): boolean => {
    const tripTypes: Array<TripTypes> = banner.tripType ?? [TripTypes.ALL];

    return tripTypes.some((tripType: TripTypes) => {
      if (
        pagesIncludesPath(disallowedPages, currentPath) ||
        pagesMatchesPath(disallowedPages, currentPath) ||
        pagesHasPathSegment(disallowedPages, currentPath)
      ) {
        return false;
      }

      return tripType === tripTypeProps;
    });
  };

  const getActiveBanners = (currentPath: string, tripType: string): Array<PromoBannerData> => {
    return promoBanners.filter((banner: PromoBannerData) => {
      const activeFromTimestamp: number | null = banner.activeFrom ? new Date(banner.activeFrom).getTime() : null;
      const activeToTimestamp: number | null = banner.activeTo ? new Date(banner.activeTo).getTime() : null;
      const currentTimestamp: number = new Date().getTime();

      const isActive: boolean =
        (!activeFromTimestamp || (!!activeFromTimestamp && activeFromTimestamp <= currentTimestamp)) &&
        (!activeToTimestamp || (!!activeToTimestamp && activeToTimestamp >= currentTimestamp));

      return isActive && displayBanner(banner, currentPath, tripType);
    });
  };

  if (!tripType) {
    return null;
  }

  const activeBanners: Array<PromoBannerData> = getActiveBanners(currentPath, tripType);

  if (activeBanners.length === 0) {
    return null;
  }

  const firstBanner: PromoBannerData = activeBanners[0];
  const { backgroundColour, textColour, title, text, mobileText, activeTo } = firstBanner;
  const handleOnClick = () => dispatch(showModal(ModalType.PROMO_BANNER_MODAL, firstBanner.modal));
  const bannerText = isMobile ? mobileText : text;

  return (
    <PromoBanner
      bannerLinkText={isMobile ? '' : title}
      activeTo={activeTo}
      bannerText={bannerText}
      bannerBackgroundColor={backgroundColour}
      bannerTextColor={textColour}
      onClick={handleOnClick}
    />
  );
};
