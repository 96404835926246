import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { Page } from '@model/common';
import Cookies, { Cookie } from '@model/common/cookie/cookie';
import { CustomerApi } from '@model/iceberg/service/customer/customer-api';
import { getHistory } from '@state/app';
import { ModalType, showModal, closeModal } from '@state/modal/modalOperations';
import { getModalType } from '@state/modal/modalSelectors';
import { getPromoBannerData } from '@state/promo-banner/promoBannerSelectors';
import { withIsServer } from '@components/hoc';
import { BaseModal } from '@components/common/modal';
import { PaywallBannerModal } from './PaywallBannerModal';
import { AppVariant } from '@model/config/brands';
import { GtmBootstrap } from '@model/bootstrap/gtm-bootstrap';

const PATHS_BLACKLIST = [Page.MY_BOOKING, Page.AGENTS, Page.HELP, Page.HELP_CENTRE];

const COOKIES_BLACKLIST = [Cookie.SFSK, Cookie.TRADE_AUTHORIZATION, Cookie.EHID];

const BANNER_ENABLED = true;
const SAVINGS_AMOUNT = '£25';

const hashString = async (string: string) => {
  const cleanString = string.toLowerCase().trim().replace(/\s/g, '');
  const utf8 = new TextEncoder().encode(cleanString);
  const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map((bytes) => bytes.toString(16).padStart(2, '0')).join('');
  return hashHex;
};

interface PaywallBannerModalContainerProps {
  isServer: boolean;
}

export const PaywallBannerModalContainer = withIsServer(({ isServer }: PaywallBannerModalContainerProps) => {
  const dispatch = useDispatch();
  const pagesViewed = useSelector(getHistory);
  const promoBannerData = useSelector(getPromoBannerData);
  const promoModalData =
    promoBannerData && Array.isArray(promoBannerData)
      ? promoBannerData.find(({ tripType }) => isEmpty(tripType))?.modal
      : undefined;

  const router = useRouter();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const modalType = useSelector(getModalType);
  const modalVisible = modalType === ModalType.PAYWALL_BANNER_MODAL;
  const CookiesApi = new Cookies();
  const modalClosed = CookiesApi.get(Cookie.CANCEL_PROMO);
  const [isModalOpen, setIsModalOpen] = useState(modalClosed !== 'true');

  const blacklistCookieIsPresent = !!COOKIES_BLACKLIST.find((blacklistCookie) => CookiesApi.get(blacklistCookie));

  const pathIsBlackListed = (path: string) =>
    !!PATHS_BLACKLIST.find((blacklistPath) => path.indexOf(blacklistPath) === 0);

  const handleRouteChange = (url: string) => {
    const pageViewCount = pagesViewed.filter((path) => !pathIsBlackListed(path)).length;
    if (!pathIsBlackListed(url) && pageViewCount > 1 && isModalOpen) {
      dispatch(showModal(ModalType.PAYWALL_BANNER_MODAL));
    }
  };

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  if (isServer || !BANNER_ENABLED) return null;

  if (blacklistCookieIsPresent || !modalVisible) {
    return null;
  }

  const handleSubscribe = async (email: string) => {
    if (executeRecaptcha) {
      const reCaptcha = await executeRecaptcha('contact');
      const utmParams = new GtmBootstrap().getUtmParams();

      await new CustomerApi().subscribeToNewsletter({
        email,
        reCaptcha,
        ...utmParams
      });

      const ehidClean = await hashString(email);
      CookiesApi.set({
        id: Cookie.EHID,
        value: ehidClean, // generate SHA
        options: {
          path: '/',
          expires: moment().add(2, 'years').toDate()
        }
      });

      const _paq = ((window as any)['_paq'] || []) as Array<Array<string | number>>;
      _paq.push(['trackGoal', 'signupModal', 1]);

      dispatch(showModal(ModalType.PROMO_BANNER_MODAL, promoModalData));
    }
  };

  const handleDismiss = () => {
    setIsModalOpen(false);
    dispatch(closeModal());
  };

  return (
    <>
      {!AppVariant.isWhitelabel() && (
        <BaseModal
          open={isModalOpen}
          hideCloseButton
          isScrollBodyModal
          bgColor={'rgba(0,0,0,0.2)'}
          bdFilter={'blur(2px)'}
        >
          <PaywallBannerModal onSubscribe={handleSubscribe} onDismiss={handleDismiss} amount={SAVINGS_AMOUNT} />
        </BaseModal>
      )}
    </>
  );
});
