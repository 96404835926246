import { useState, useEffect } from 'react';
import { useI18NextContext } from '@components/hooks';
import { getTimeOffsetDisplay } from '@util/date-time';
import { BannerBase, PromoBannerProps } from '../base/BannerBase';

interface CountdownBannerProps extends PromoBannerProps {
  activeTo?: string;
}

const TIMER_NAME = 'countdownTimer';

export const CountdownBanner = (props: CountdownBannerProps) => {
  const { activeTo, bannerText } = props;
  const t = useI18NextContext();
  const initialTextValue = activeTo ? t(bannerText, { [TIMER_NAME]: getTimeOffsetDisplay(activeTo) }) : bannerText;
  const [timedBannerText, setTimedBannerText] = useState(initialTextValue);

  const updateBannerText = () => {
    if (activeTo) {
      const timeOffset = getTimeOffsetDisplay(activeTo);
      setTimedBannerText(t(bannerText, { [TIMER_NAME]: timeOffset }));
    }
  };

  let timer;
  useEffect(() => {
    if (activeTo) {
      const strRegExp = new RegExp(TIMER_NAME);
      if (strRegExp.test(bannerText)) {
        timer = window.setInterval(updateBannerText, 1000);
        return () => window.clearInterval(timer);
      }
    }
  }, []);
  return <BannerBase {...props} bannerText={timedBannerText} />;
};
